.App {
  text-align: center;
  padding: 5px;
  /* background: #8674a7; fallback for old browsers */
  /* background: -webkit-linear-gradient(to top,  #8674a7, #451968); Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to top, #8674a7, #451968) W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+*/
  background: #eef2f3;
  min-height: 100vh;
  /* background: -webkit-linear-gradient(to top,#EEF2F3 ,#8E9EAB );
  background: linear-gradient(to bottom,#EEF2F3 ,#8E9EAB ); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiDataGrid-columnHeaders {
  background-color: #111;
  color: white;
}

.MuiDataGrid-row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainTrades {
  display: flex;
  --s: 125px; /* size  */
  --m: 4px; /* margin */
  --f: calc(var(--s) * 1.732 + 4 * var(--m) - 1px);
}

.tradeContainer {
  width: 400px;
  font-size: 0; /* disable white space between inline block element */
}

.tradeContainer div {
  width: var(--s);
  margin: var(--m);
  height: calc(var(--s) * 1.1547);
  display: inline-block;
  font-size: initial;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  margin-bottom: calc(var(--m) - var(--s) * 0.2885);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.75);
}

.tradeContainer::before {
  content: "";
  width: calc(var(--s) / 2 + var(--m));
  float: left;
  height: 120%;
  shape-outside: repeating-linear-gradient(
    #0000 0 calc(var(--f) - 3px),
    #000 0 var(--f)
  );
}


.row {
  display: grid;
  width:100%;
  grid-template-columns: 10% 10% 20% 30% 10% 10% 10%;
  grid-template-rows: 30px;
  gap: 30px;
}

.skeleton {
  background: #e1e1e1;
  border-radius: 4px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e8e8e8 50%,
    transparent 100%
  );
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}